import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadState, setUploadState] = useState(false);

  useEffect(() => {
    const totalSize = selectedFiles.reduce((size, file) => size + file.size, 0);
    if(totalSize !== 0) {
      const progress = Math.round((uploadedSize * 100) / totalSize);
      setUploadProgress(progress);
    }
  }, [uploadedSize, selectedFiles]);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        setUploadedSize((prevSize) => prevSize + progressEvent.bytes);
      },
    };
  
    try {
      const response = await axios.post('https://upload.simplymail.it/upload', formData, config);
      const data = response.data;
      setUploadedFiles((prevArray) => [...prevArray, data]);
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const uploadAllFiles = () => {
    setUploadState(true);
    setUploadedFiles([]);
    setUploadProgress(0);
    setUploadedSize(0);
    const uploadPromises = selectedFiles.map(uploadFile);
    Promise.all(uploadPromises).then(() => {
      setSelectedFiles([]);
    });
  };

  return (
    <div>
      <h2>Bilder/ Videos für 60. Geburtstag Stefan Egelhaaf</h2>
      {!uploadState && (
        <form>
          <div className="file-input">
            <input
              type="file"
              name="file"
              accept="image/*, video/*"
              onChange={handleFileChange}
              required
              multiple
            />
            <label>Datei auswählen</label>
            <span className="file-count">
              {selectedFiles.length > 0
                ? selectedFiles.length + ' Dateien ausgewählt'
                : 'Keine Datei ausgewählt'}
            </span>
          </div>
          <button type="button" onClick={uploadAllFiles}>
            Hochladen
          </button>
        </form>
      )}
      {uploadState && (
        <div>
          <h3>Dateien werden hochgeladen...</h3>
          <h3>Bitte auf der Seite bleiben und warten bis 100% angezeigt werden</h3>
          <div id="progress">
            <div
              id="progress-bar"
              style={{ width: `${uploadProgress}%` }}
            ></div>
            <div id="progress-text">{Math.round(uploadProgress)}%</div>
          </div>
          <p class="count-upload">{uploadedFiles.length} Dateien hochgeladen</p>
        </div>
      )}
      {uploadState && Math.round(uploadProgress) === 100 && (
        <div id="upload-message">
          <h3>Upload abgeschlossen</h3>
          <button type="button" onClick={() => setUploadState(false)}>
            Mehr Hochladen
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
